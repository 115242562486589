<template>

  <div class="container">

    <!--        <header class="header">-->
    <!--            &lt;!&ndash;        <button type="button" class="menu"&ndash;&gt;-->
    <!--            &lt;!&ndash;                @click.prevent="changeCount">&ndash;&gt;-->
    <!--            &lt;!&ndash;            <span></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <span></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <span></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;        </button>&ndash;&gt;-->
    <!--        </header>-->

    <div class="title" ref="title">
      <p>🥳 Приветствуем 👋<br> в клубе любителей MINI</p>
    </div>

    <div class="logo" ref="logo">
      <Logo></Logo>
    </div>

    <div class="info" ref="info">
      <p>вступай в наши сообщества:</p>
    </div>

    <div class="grid">

      <a href="https://t.me/joinchat/T7kLxseFyIDlTrv8" class="block" ref="block1">
        <div class="block__icon">
          <i class='bx bxl-telegram'></i>
        </div>
        <div class="block__info">
          <div class="block__title">
            <p>Telegram</p>
          </div>
          <div class="block__subtitle">
            <p>300+ участников | общение | ивенты</p>
          </div>
        </div>
      </a>

      <a href="https://www.instagram.com/miniclub_od/" class="block block--instagram" ref="block2">
        <div class="block__icon">
          <i class='bx bxl-instagram' ></i>
        </div>
        <div class="block__info">
          <div class="block__title">
            <p>Instagram</p>
          </div>
          <div class="block__subtitle">
            <p>420+ подписчиков | фотографий | информация</p>
          </div>
        </div>
      </a>

    </div>

  </div>

</template>

<script>
    import {gsap} from "gsap";

    import Logo from '.././components/Logo';

    export default {

        components: {
            Logo
        },

        mounted() {
            const logo = this.$refs.logo;
            const title = this.$refs.title;
            const info = this.$refs.info;
            const block1 = this.$refs.block1;
            const block2 = this.$refs.block2;

            let tl = gsap.timeline({delay: 1});
            tl
                .fromTo(logo,
                    {scale: 0, opacity: 0},
                    {delay: .5, scale: 1, opacity: 1, duration: 1.2,
                        ease: "elastic.out(0.75, 0.3)"})
                .fromTo(title, {opacity: 0}, {opacity: 1, duration: 1, ease: "power2.out" }, "-=.5")
                .fromTo(info, {opacity: 0}, {opacity: 1, duration: 1,  ease: "power2.out"}, "-=.5")
                .fromTo(block1, {opacity: 0, y: 20}, {opacity: 1, y: 0, duration: 1, ease: "power2.out"}, "-=.5")
                .fromTo(block2, {opacity: 0, y: 20}, {opacity: 1, y: 0, duration: 1, ease: "power2.out"}, "-=.5" )
            ;
        }
    }

</script>

