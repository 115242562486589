import { createWebHistory, createRouter } from "vue-router";

import Contact from "../components/Contact";
import Map from "../components/Map";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Contact,
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;