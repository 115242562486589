<template>
    <router-view/>
</template>

<script>

    export default {}

</script>

