<template>
  <div class="mini">
    <MiniIcon ref="miniIcon"></MiniIcon>
  </div>
  <div id="map"></div>
</template>

<script>
    import MiniIcon from './Mini';

    export default {
        name: 'Map',

        components: {
            MiniIcon
        },

        data() {
            return {
                map: null
            }
        },

        methods: {
            init() {
                mapboxgl.accessToken = 'pk.eyJ1IjoiamFzb25zdGhtIiwiYSI6ImNramhnZnR5ajBvczUyenFvdWI4d3o4YWQifQ._WyDjt48yVawU10F8PtKzQ';
                this.map = new mapboxgl.Map({
                    container: 'map',
                    // style: 'mapbox://styles/mapbox/light-v10',
                    style: 'mapbox://styles/mapbox/dark-v10',
                    center: [30.740883, 46.469391],
                    zoom: 12
                });

                setTimeout(() => {
                    this.map.setLayoutProperty('country-label', 'text-field', [
                        'get',
                        'name_ru'
                    ]);
                }, 100)


                // mapboxgl.accessToken = 'pk.eyJ1IjoiamFzb25zdGhtIiwiYSI6ImNramhnZnR5ajBvczUyenFvdWI4d3o4YWQifQ._WyDjt48yVawU10F8PtKzQ';
                // let map = new mapboxgl.Map({
                //     container: 'map',
                //     style: 'mapbox://styles/mapbox/light-v10',
                //     // style: 'mapbox://styles/mapbox/dark-v10',
                //     center: [30.740883, 46.469391],
                //     zoom: 12
                // });
                // map.setLayoutProperty('country-label', 'text-field', [
                //     'get',
                //     'name_ru'
                // ]);
            }
        },

        mounted() {
            this.init();
            let geo = navigator.geolocation;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((pos) => {
                    console.log(pos.coords.latitude, pos.coords.longitude);

                    let mark =  document.querySelector('.mini');
                    let marker = new mapboxgl.Marker(mark)
                        .setLngLat([pos.coords.longitude, pos.coords.latitude])
                        .addTo(this.map);

                    // setTimeout(()=>{
                    //     marker.setLngLat([30.740883, 46.469391]);
                    //     this.map.flyTo({
                    //         center: [30.740883, 46.469391]
                    //     });
                    // }, 1000);

                    // console.log(marker.setLngLat([pos.coords.longitude, pos.coords.latitude]));

                });
            }


        }
    }
</script>

<style lang="scss">

  #map {
    width: 100%;
    height: 100%;
  }

  .mini {
    /*max-width: 50px;*/
    /*height: 50px;*/
    /*width: 100%;*/

    /*svg {*/
    /*  max-width: 50px;*/
    /*  width: 100%;*/
    /*}*/
  }

</style>